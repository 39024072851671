import { defineStore } from 'pinia';
import { client } from '@/apollo';
import * as yup from 'yup';

import type {
  Eatfresh_User_Account,
  Fetch_User_By_Id_Anonymous_QueryQuery,
  UserAccountsQuery,
  UserAccountsQueryVariables,
} from '@/gql/graphql';
import type { Eatfresh_Basic_User_Type_Enum as BasicUserType } from '@/gql/graphql';
import { graphql } from '@/gql';
import { ApiRequest } from '@/stores/shared_store';
import type { IAnonymousUser } from '../../../../backend/api/src/model/user_account/basicUser.interface';
import type { Eatfresh_Basic_User_Type_Enum } from '@/gql/graphql';
import { useAuthStore } from '@/stores/auth';

export enum PasswordCreationTypeFE {
  GENERATE = 'GENERATE',
  SPECIFY = 'SPECIFY',
}

type UserAccount = UserAccountsQuery['eatfresh_user_account'][0];
type AnonymousUserAccountInputFields = Pick<UserAccount, 'basic_user_type'>;
type UserAccountInputFields = Partial<
  Pick<UserAccount, 'email' | 'active' | 'basic_user_type'> & {
    password?: string;
  }
>;
type RegisterAnonymousUserFields = {
  email: string;
  basic_user_type: Eatfresh_Basic_User_Type_Enum;
  has_password?: boolean;
  password?: string;
  agb_accepted: boolean;
  active?: boolean;
};
interface ApiRequestRequestParameters {
  url: string;
  body: {
    id: string;
    newPassword: string;
  };
  options?: {
    useAuthorization?: boolean;
    responseType?: 'string' | 'json';
    headers?: Array<{ name: string; value: string }>;
  };
}
interface CreateUserProperties {
  email: string;
  active: boolean;
  basic_user_type: BasicUserType;
  password_option: PasswordCreationTypeFE;
  password: string;
  has_password?: boolean;
  agb_accepted: boolean;
  biometrics?: boolean;
}
//BeforeInstallPromptEvent is not yet universally supported and as such does not have an official type
export interface BeforeInstallPromptEvent extends Event {
  readonly platforms: Array<string>;
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed';
    platform: string;
  }>;
  prompt(): Promise<void>;
}
interface UserStoreState {
  users: UserAccount[];
  totalUserCount: number;
  isLoading: boolean;
  currentUser:
    | undefined
    | Fetch_User_By_Id_Anonymous_QueryQuery['eatfresh_user_account'][number];
  pwaInstallation: {
    beforeInstallPromptEvent: null | BeforeInstallPromptEvent;
    newRegister: boolean;
  };
}

const FETCH_USER_ACCOUNTS_QUERY = graphql(`
  query UserAccounts(
    $where: eatfresh_user_account_bool_exp
    $pageSize: Int
    $offset: Int
  ) {
    eatfresh_user_account(
      where: $where
      order_by: { last_name: asc }

      limit: $pageSize
      offset: $offset
    ) {
      ...UserFragment
    }
  }
`);
const FETCH_USER_BY_ID_QUERY = graphql(`
  query FETCH_USER_BY_ID_QUERY($user_ID: uuid!) {
    eatfresh_user_account(where: { id: { _eq: $user_ID } }) {
      ...UserFragment
    }
  }
`);
const FETCH_USER_BY_ID_ANONYMOUS_QUERY = graphql(`
  query FETCH_USER_BY_ID_ANONYMOUS_QUERY($user_ID: uuid!) {
    eatfresh_user_account(where: { id: { _eq: $user_ID } }) {
      ...AnonUserFragment
    }
  }
`);

const FETCH_USER_BY_EMAIL_QUERY = graphql(`
  query FETCH_USER_BY_EMAIL_QUERY($email: String!) {
    eatfresh_user_account(where: { email: { _eq: $email } }) {
      ...AnonUserFragment
    }
  }
`);

const FETCH_LATEST_PURCHASES_QUERY = graphql(`
  query LatestPurcheses($user_ID: uuid!) {
    eatfresh_latest_order_positions(
      where: { user_id: { _eq: $user_ID } }
      order_by: { created: desc }
      limit: 5
    ) {
      article_name
      created
      wholesale_price
      order_id
      url
    }
  }
`);
interface GraphQlWhereClause {
  [key: string]: {
    [key: string]: string;
  };
}
export const useUserStore = defineStore({
  id: 'user',
  state: (): UserStoreState => ({
    users: [],
    totalUserCount: 0,
    isLoading: false,
    currentUser: undefined,
    pwaInstallation: {
      beforeInstallPromptEvent: null,
      newRegister: false,
    },
  }),
  getters: {},
  actions: {
    //  GET user
    async fetchUsers({
      searchString = '',
      searchProperty = undefined,
      page = 1,
      pageSize = 10,
    }: {
      page?: number;
      pageSize?: number;
      searchString?: string;
      searchProperty?: { property: string; value: string; matcher: string };
    }) {
      this.isLoading = true;
      const offset = (page - 1) * pageSize;

      const whereClause: GraphQlWhereClause = {
        last_name: {
          _ilike: '%' + searchString + '%',
        },
      };
      if (searchProperty) {
        whereClause[searchProperty.property] = {
          [searchProperty.matcher]: searchProperty.value,
        };
      }

      const userQueryResult = await client().query<
        UserAccountsQuery,
        UserAccountsQueryVariables
      >({
        query: FETCH_USER_ACCOUNTS_QUERY,
        variables: {
          where: whereClause,
          offset,
          pageSize,
        },
      });
      this.users = userQueryResult.data.eatfresh_user_account;
      this.isLoading = false;
    },
    //  DELETE user
    async deleteUser({ userId }: { userId: string }) {
      const res = await ApiRequest<{ message: string }>({
        url: '/rest/user/delete',
        body: {
          id: userId,
        },
        options: { responseType: 'json' },
      });
      return res;
    },
    //  POST user
    async createUser(userProperties: CreateUserProperties) {
      const response = await ApiRequest<{
        database: UserAccount;
      }>({
        url: '/rest/user/create',
        body: {
          requires_password_change:
            userProperties.password_option === PasswordCreationTypeFE.GENERATE,
          ...userProperties,
          password: userProperties.password ?? '', // Provide a default empty string value for the password
        },
        options: { responseType: 'json' },
      });
      return response;
    },
    async fetchUserById(id: string) {
      console.log('sadsa', id);
      const userQueryResult = await client().query({
        query: FETCH_USER_BY_ID_QUERY,
        variables: { user_ID: id },
      });
      const fetchedUser = userQueryResult.data.eatfresh_user_account[0];

      // return fetchedUser
      this.currentUser = fetchedUser;
      return fetchedUser;
    },
    async fetchUserByIdAnonymous(id: string) {
      console.log('sadsa', id);
      const userQueryResult = await client().query({
        query: FETCH_USER_BY_ID_ANONYMOUS_QUERY,
        variables: { user_ID: id },
      });
      const fetchedUser = userQueryResult.data.eatfresh_user_account[0];

      // return fetchedUser
      this.currentUser = fetchedUser;
      return fetchedUser;
    },
    async fetchUserByEmail(email: string) {
      console.log(email);
      const lowerCaseEmail = email.toLowerCase();
      const userQueryResult = await client().query({
        query: FETCH_USER_BY_EMAIL_QUERY,
        variables: { email: lowerCaseEmail },
      });
      console.log(userQueryResult);
      const fetchedUser = userQueryResult.data.eatfresh_user_account[0];

      // return fetchedUser
      this.currentUser = fetchedUser;
      return fetchedUser;
    },
    async fetchUsersLastBoughtArticles(id: string) {
      const lastArticlesBoughtQueryResult = await client().query({
        query: FETCH_LATEST_PURCHASES_QUERY,
        variables: { user_ID: id },
      });
      return lastArticlesBoughtQueryResult.data.eatfresh_latest_order_positions;
    },
    async updateUserPassword({
      userId,
      newPassword,
      authToken,
    }: {
      userId: string;
      newPassword: string;
      authToken?: string;
    }) {
      // login as admin
      const requestParameters: ApiRequestRequestParameters = {
        url: '/rest/user/update-password',
        body: {
          id: userId,
          newPassword,
        },
      };
      if (authToken) {
        requestParameters['options'] = {
          headers: [{ name: 'Authorization', value: `Bearer ${authToken}` }],
        };
      }
      return await ApiRequest<string>(requestParameters);
    },
    async updateUser({
      userId,
      updatedUserProperties,
    }: {
      userId: string;
      updatedUserProperties: UpdateUserRequest;
    }) {
      return await ApiRequest<Omit<Eatfresh_User_Account, 'password'>>({
        url: '/rest/user/update',
        body: {
          id: userId,
          ...updatedUserProperties,
        },
        options: { responseType: 'json' },
      });
    },
    async createAnonymousUser(
      anonymousUserProperties: AnonymousUserAccountInputFields,
    ) {
      const authStore = useAuthStore();
      const anonymousUserHeaders = new Headers();
      anonymousUserHeaders.append('Content-Type', 'application/json');
      anonymousUserHeaders.append(
        'Authorization',
        'Bearer ' + authStore.authToken,
      );
      const createProps: AnonymousUserAccountInputFields = {
        basic_user_type: anonymousUserProperties.basic_user_type,
        // Should always be 'ANONYMOUS'
      };
      const userRequestOptions = {
        method: 'POST',
        headers: anonymousUserHeaders,
        body: JSON.stringify(createProps),
      };

      const response = await fetch(
        '/rest/user/create-anonymous',
        userRequestOptions,
      );
      console.log(response);
      const responseMessage = (await response.json()) as {
        database: IAnonymousUser;
      };
      console.log(responseMessage);
      if (response.ok) {
        return {
          status: 200,
          success: true,
          message: responseMessage,
        } as {
          status: 200;
          success: true;
          message: {
            database: IAnonymousUser;
          };
        };
      }
      return {
        status: 500,
        success: false,
        message: 'error',
      } as { status: 500; success: false; message: string };
    },
    async registerAnonymousUser({
      userId,
      registerProperties,
    }: {
      userId: string;
      registerProperties: RegisterAnonymousUserFields;
    }) {
      return await this.updateUser({
        userId,
        updatedUserProperties: registerProperties,
      });
    },
    async acceptTermsAndConditions() {
      const authStore = useAuthStore();
      const acceptTermsHeaders = new Headers();

      if (!authStore.id) {
        throw new Error('No user id provided');
      }
      acceptTermsHeaders.append('Content-Type', 'application/json');
      acceptTermsHeaders.append(
        'Authorization',
        'Bearer ' + authStore.authToken,
      );

      const acceptTermsBody = {
        id: authStore.id,
      };

      const acceptTermsOptions = {
        method: 'POST',
        headers: acceptTermsHeaders,
        body: JSON.stringify(acceptTermsBody),
      };

      try {
        const response = await fetch(
          '/rest/user/accept-terms',
          acceptTermsOptions,
        );

        return response;
      } catch (errors) {
        console.error('errors: ', errors);
        throw errors;
      }
    },
  },
});

export const updateUserSchema = yup.object({
  email: yup.string().required().email(),
  first_name: yup.string(),
  last_name: yup.string(),
  agb_accepted: yup.boolean(),
  password: yup.string(),
  has_password: yup.boolean(),
  biometrics: yup.boolean(),
});

export type UpdateUserRequest = yup.InferType<typeof updateUserSchema>;
