import { createApp, provide, h } from 'vue';
import { DefaultApolloClient } from '@vue/apollo-composable';
import { client as apolloClient } from './apollo';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';
import PrimeVue from 'primevue/config';
import './assets/main.css';
import i18n from './i18n';
import { googleLog } from '@/stores/shared_store';
import * as Sentry from '@sentry/vue';
//theme
import './theme.css';

//core
import 'primevue/resources/primevue.min.css';

//primeflex
import 'primeflex/primeflex.css';

//icons
import 'primeicons/primeicons.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faSeedling,
  faLeaf,
  faFingerprint,
  faCreditCard
} from '@fortawesome/free-solid-svg-icons';

library.add(faSeedling);
library.add(faLeaf);
library.add(faFingerprint);
library.add(faCreditCard);
// components
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
const app = createApp({
  render: () => h(App),
  setup() {
    provide(DefaultApolloClient, apolloClient());
  },
});
Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DNS,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.browserApiErrorsIntegration(),
    Sentry.sessionTimingIntegration(),
    Sentry.replayIntegration(),

    Sentry.captureConsoleIntegration({ levels: ['error'] }),
    Sentry.rewriteFramesIntegration(),
    Sentry.extraErrorDataIntegration()
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/europe-west3-eatfresh-cloud-connect\.cloudfunctions\.net\/eatfresh-api-dev/,
    /^https:\/\/europe-west3-eatfresh-cloud-connect\.cloudfunctions\.net\/eatfresh-api-prod/,
    'https://www.computop-paygate.com/payNow.aspx',
    'https://pay.google.com/gp/p/js/pay.js',
    'https://applepay.cdn-apple.com/jsapi/v1.1.0/apple-pay-sdk.js'
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0,  // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
app.use(createPinia());
app.use(PrimeVue);
app.use(i18n);
app.use(router);
console.log('VUE ROUTER MOUNTED');
app.use(PrimeVue);
app.use(ToastService);
app.component('font-awesome-icon', FontAwesomeIcon);
app.component('InputText', InputText);
app.component('PButton', Button);
// eslint-disable-next-line vue/multi-word-component-names
app.component('Toast', Toast);

app.config.globalProperties.$googleLog = googleLog;

app.mount('#app');
