import { defineStore } from 'pinia';
import jwtDecode from 'jwt-decode';
import {
  Eatfresh_Basic_User_Type_Enum,
  Eatfresh_Password_Reset_Token_Status_Enum,
  type Eatfresh_User_Account,
} from '@/gql/graphql';
import { ApiRequest, useSharedStore } from '@/stores/shared_store';
import { client, setApolloAuthToken } from '@/apollo';
import type {
  AuthenticationResponseJSON,
  RegistrationResponseJSON,
} from '@simplewebauthn/typescript-types';
import * as yup from 'yup';
import { PASSWORD_RESET_TOKEN_QUERY } from '@/graphql/queries';

interface AuthStoreState {
  authToken: string | null;
  email: string | null;
  id: string | null;
  role: Eatfresh_Basic_User_Type_Enum | null;
  requiresPasswordChange: boolean | null;
}

type DecodedToken = {
  'https://hasura.io/jwt/claims'?: {
    'x-hasura-user-id'?: string;
    'x-hasura-default-role'?: Eatfresh_Basic_User_Type_Enum;
  };
  requiresUserPasswordChange?: boolean;
  email?: string;
};
export const useAuthStore = defineStore({
  id: 'auth',
  state: (): AuthStoreState => ({
    authToken: null,
    email: null,
    id: null,
    role: null,
    requiresPasswordChange: null,
  }),
  getters: {},
  actions: {
    setAuthToken(token: string | null) {
      setApolloAuthToken(token ?? '');
      this.authToken = token;

      if (!token) {
        this.email = null;
        this.id = null;
        this.role = null;
        this.requiresPasswordChange = null;
        return;
      }
      //role
      const roleFromToken = this.getUserRoleFromToken(token);
      this.role = roleFromToken;
      //id
      const idFromToken = this.getUserIdFromToken(token);

      this.id = idFromToken;
      //email
      //
      try {
        const emailFromToken = this.getEmailFromToken(token);
        this.email = emailFromToken.value;
      } catch (e) {
        this.email = null;
        if (roleFromToken !== Eatfresh_Basic_User_Type_Enum.Anonymous) {
          throw new Error(
            'Property email not found on non-anonymous jwt_token',
          );
        }
      }

      try {
        //requires_password_change
        const requiresPasswordChangeFromToken =
          this.getPasswordChangeRequiredStatusFromToken(token);
        this.requiresPasswordChange = requiresPasswordChangeFromToken.value;
      } catch (e) {
        this.requiresPasswordChange = null;
        if (roleFromToken !== Eatfresh_Basic_User_Type_Enum.Anonymous) {
          throw new Error(
            'Property requires_password_change not found on non-anonymous jwt_token',
          );
        }
      }
    },
    removeAuthToken() {
      this.setAuthToken(null);
    },

    async restoreTokenFromCookie() {
      return await ApiRequest<string>({
        url: '/rest/auth/restore-token-from-cookie',
        options: {
          useAuthorization: false,
        },
      });
    },
    async startWebAuthnRegistration(body: { email: string; id?: string }) {
      return await ApiRequest({
        url: '/rest/auth/start-registration',
        body,
      });
    },
    async finishWebAuthnRegistration(body: {
      email: string;
      id?: string;
      webAuthnResponse: RegistrationResponseJSON;
    }) {
      const result = await ApiRequest<{ token: string }>({
        url: '/rest/auth/finish-registration',
        body,
      });

      return result;
    },
    async cancelWebAuthnRegistration(body: { email: string; id?: string }) {
      return await ApiRequest({
        url: '/rest/auth/cancel-registration',
        body,
      });
    },
    async startWebAuthnLogin(email: string) {
      return await ApiRequest({
        url: '/rest/auth/start-login',
        body: {
          email,
        },
      });
    },
    async finishWebAuthnLogin(
      email: string,
      user_resp: AuthenticationResponseJSON,
    ) {
      console.log(user_resp);
      return await ApiRequest({
        url: '/rest/auth/finish-login',
        body: {
          email: email,
          webAuthnResponse: user_resp,
        },
      });
    },
    safeJwtDestructure<T>(token: string, key: keyof DecodedToken) {
      const decodedToken = jwtDecode<DecodedToken | undefined>(token);
      if (!decodedToken) {
        throw new Error('Error while decoding jwt token');
      }
      if (!decodedToken[key] && decodedToken[key] !== false) {
        throw new Error(`Error while getting ${key} from jwt token`);
      }

      return {
        value: decodedToken[key] as T,
      };
    },
    async logOut() {
      const res = await ApiRequest({
        url: '/rest/auth/logout',
      });

      this.setAuthToken(null);

      return res;
    },
    decodeToken(token: string) {
      return jwtDecode<DecodedToken | undefined>(token);
    },
    getPasswordChangeRequiredStatusFromToken(token: string) {
      return this.safeJwtDestructure<boolean>(
        token,
        'requiresUserPasswordChange',
      );
    },
    getEmailFromToken(token: string) {
      return this.safeJwtDestructure<string>(token, 'email');
    },

    getUserRoleFromToken(token: string) {
      const decoded = this.decodeToken(token);

      if (!decoded) {
        throw new Error('Couldnt decode jwt token');
      }
      if (typeof decoded !== 'object') {
        throw new Error('Invalid decoded format');
      }

      const role =
        decoded?.['https://hasura.io/jwt/claims']?.['x-hasura-default-role'];

      if (!role) {
        throw new Error('Couldnt decode property role');
      }

      return role;
    },
    getUserIdFromToken(token: string) {
      const decoded = this.decodeToken(token);

      if (!decoded) {
        throw new Error('Couldnt decode jwt token');
      }
      if (typeof decoded !== 'object') {
        throw new Error('Invalid decoded format');
      }

      const id =
        decoded?.['https://hasura.io/jwt/claims']?.['x-hasura-user-id'];

      if (!id) {
        throw new Error('Couldnt decode property id');
      }

      return id;
    },
    async loginUser({ email, password }: { email: string; password: string }) {
      const res = await ApiRequest<string>({
        url: '/rest/auth/login',
        body: {
          email: email,
          password: password,
        },
        options: {
          responseType: 'string',
          useAuthorization: false,
        },
      });

      const idFromToken = this.getUserIdFromToken(res.value); // get id from token

      return {
        data: {
          userId: idFromToken,
          authToken: res.value,
        },
        response: res.response,
      };
    },
    async loginUserBiometric(email: string) {
      const res = await ApiRequest<string>({
        url: '/rest/auth/biometriclogin',
        body: {
          email: email,
        },
        options: {
          responseType: 'string',
          useAuthorization: false,
        },
      });

      const idFromToken = this.getUserIdFromToken(res.value); // get id from token

      return {
        data: {
          userId: idFromToken,
          authToken: res.value,
        },
        response: res.response,
      };
    },

    async resetPassword(body: ResetPasswordRequest) {
      return await ApiRequest<string>({
        url: '/rest/password_reset/reset-password',
        body,
        options: {
          useAuthorization: false,
        },
      });
    },

    async confirmResetPassword(body: ConfirmResetPassword) {
      return await ApiRequest<string>({
        url: '/rest/password_reset/confirm-reset-password',
        body,
        options: {
          useAuthorization: false,
        },
      });
    },

    async getPasswordResetToken({ token }: { token: string }) {
      const res = await client().query({
        query: PASSWORD_RESET_TOKEN_QUERY,
        variables: {
          token,
        },
      });

      return res;
    },

    async updatePasswordResetToken({
      token_id,
      used,
      status,
    }: {
      token_id: string;
      used?: boolean;
      status?: Eatfresh_Password_Reset_Token_Status_Enum;
    }) {
      return await ApiRequest<string>({
        url: '/rest/password_reset/update-reset-password-token',
        body: {
          id: token_id,
          used,
          status,
        },
        options: {
          useAuthorization: false,
        },
      });
    },

    async deletePasswordResetToken(token_id: string) {
      return await ApiRequest<string>({
        url: '/rest/password_reset/delete-reset-password-token',
        body: {
          id: token_id,
        },
        options: {
          useAuthorization: false,
        },
      });
    },

    async checkValidLoginCredentials({
      email,
      password,
    }: {
      email: string;
      password: string;
    }) {
      const sharedStore = useSharedStore();
      const ApiRequest = sharedStore.ApiRequest;
      return ApiRequest<{ data: Eatfresh_User_Account }>({
        url: '/rest/auth/check-login',
        body: {
          email,
          password,
        },
        options: {
          responseType: 'json',
          useAuthorization: false,
        },
      });
    },

    async loginAnonymousUser(id: string): Promise<{
      response: Response;
      data: { userId: string; token: string };
    }> {
      const loginRes = await ApiRequest<string>({
        url: '/rest/auth/anonymous-login',
        body: {
          id,
        },
        options: {
          responseType: 'string',
          useAuthorization: false,
        },
      });

      const token = loginRes.value;
      this.setAuthToken(token); // set token
      const idFromToken = this.getUserIdFromToken(token); // get id from token

      return {
        data: {
          userId: idFromToken,
          token,
        },
        response: loginRes.response,
      };
    },
  },
});

export const resetPasswordRequestSchema = yup.object().shape({
  email: yup.string().email().required(),
});

export type ResetPasswordRequest = yup.InferType<
  typeof resetPasswordRequestSchema
>;

export const confirmResetPasswordSchema = yup.object().shape({
  token_id: yup.string().required(),
  user_id: yup.string().required(),
  newPassword: yup.string().required(),
});

export type ConfirmResetPassword = yup.InferType<
  typeof confirmResetPasswordSchema
>;

export const passwordLoginSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

export type PasswordLoginRequest = yup.InferType<typeof passwordLoginSchema>;
