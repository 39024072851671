import { createI18n } from 'vue-i18n';
// import de from '@/langs/de.json';
import en from '@/langs/en.json';

const i18n = createI18n({
  allowComposition: true,
  fallbackLocale: 'de',
  locale: 'en-US',
  messages: {
    // de,
    'en-US': en,
  },
  // something vue-i18n options here ...
});

export default i18n;
