import { graphql } from '@/gql/gql';
import { gql } from '@apollo/client/core';

export const FRIDGE_SCANNED_QUERY = graphql(`
  query FridgeScanned($given_id: String!) {
    eatfresh_fridge(where: { given_id: { _eq: $given_id } }) {
      id
      door_status
      disabled
      connection_status
      temperature
      longitude
      latitude
      given_id
    }
  }
`);

export const CUSTOMER_ORDERS_QUERY = graphql(`
  query CustomerOrders($user_id: uuid!, $offset: Int!, $pageSize: Int!) {
    eatfresh_customer_order(
      where: {
        user_id: { _eq: $user_id }
        order_status: { _in: [SUCCESS, DISTURBANCE] }
        order_positions_aggregate: { count: { predicate: { _gt: 0 } } }
      }
      order_by: { created: desc }
      limit: $pageSize
      offset: $offset
    ) {
      ...OrderFragment
    }
    eatfresh_customer_order_aggregate(
      where: {
        user_id: { _eq: $user_id }
        order_status: { _in: [SUCCESS, DISTURBANCE] }
        order_positions_aggregate: { count: { predicate: { _gt: 0 } } }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`);

export const ORDERS_AGGREGATE_QUERY = graphql(`
  query OrderAggregate($user_id: uuid!) {
    eatfresh_customer_order_aggregate(
      where: {
        user_id: { _eq: $user_id }
        order_status: { _in: [SUCCESS, DISTURBANCE] }
        order_positions_aggregate: { count: { predicate: { _gt: 0 } } }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`);

export const ADMIN_USERS_QUERY = graphql(`
  query AdminUsers(
    $limit: Int!
    $offset: Int!
    $selectedUserTypes: [eatfresh_basic_user_type_enum!]!
    $firstNameExp: String_comparison_exp!
    $lastNameExp: String_comparison_exp!
    $emailExp: String_comparison_exp!
    $activeIn: [Boolean!]!
    $requires_password_changeIn: [Boolean!]!
  ) {
    eatfresh_user_account(
      limit: $limit
      offset: $offset
      where: {
        basic_user_type: { _in: $selectedUserTypes }
        first_name: $firstNameExp
        last_name: $lastNameExp
        email: $emailExp
        active: { _in: $activeIn }
        requires_password_change: { _in: $requires_password_changeIn }
      }
    ) {
      ...UserFragment
    }

    eatfresh_user_account_aggregate(
      where: {
        basic_user_type: { _in: $selectedUserTypes }
        first_name: $firstNameExp
        last_name: $lastNameExp
        email: $emailExp
        active: { _in: $activeIn }
        requires_password_change: { _in: $requires_password_changeIn }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`);

export const ADMIN_ORDERS_QUERY = graphql(`
  query AdminOrders(
    $offset: Int!
    $pageSize: Int!
    $before: timestamptz
    $after: timestamptz
    $selectedStatuses: [eatfresh_order_status_enum!]!
    $selectedPaymentStatuses: [eatfresh_payment_status_enum!]!
    $userEmailLike: String!
  ) {
    eatfresh_customer_order(
      where: {
        created: { _lt: $before, _gt: $after }
        order_status: { _in: $selectedStatuses }
        payment_status: { _in: $selectedPaymentStatuses }
        _or: [
          { user_account: { email: { _ilike: $userEmailLike } } }
          { user_account: { email: { _is_null: true } } }
        ]
      }
      order_by: { created: desc }
      limit: $pageSize
      offset: $offset
    ) {
      ...OrderFragment
      user_account {
        id
        email
      }
    }
    eatfresh_customer_order_aggregate(
      where: {
        created: { _lt: $before, _gt: $after }
        order_status: { _in: $selectedStatuses }
        payment_status: { _in: $selectedPaymentStatuses }
        user_account: { email: { _ilike: $userEmailLike } }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`);

export const ADMIN_ORDER_QUERY = graphql(`
  query AdminOrder($id: uuid!, $offset: Int!, $pageSize: Int!) {
    eatfresh_customer_order_by_pk(id: $id) {
      ...OrderFragment
      user_account {
        id
        email
      }
      order_positions(offset: $offset, limit: $pageSize) {
        id
        article_name
        gross_sales_price
        quantity
        tax_rate
      }
    }

    eatfresh_order_position_aggregate(where: { order_id: { _eq: $id } }) {
      aggregate {
        count
      }
    }
  }
`);

export const FETCH_FRIDGE_STOCK_LEVEL = graphql(`
  query FridgeStockLevel($id: uuid!) {
    eatfresh_fridge_stock_aggregate(where: { fridge_id: { _eq: $id } }) {
      aggregate {
        count
      }
    }
  }
`);

export const LOGGED_IN_USER_QUERY = graphql(`
  query LoggedInUser($id: uuid!) {
    eatfresh_user_account_by_pk(id: $id) {
      ...UserFragment
    }
  }
`);

export const ADMIN_RFIDS_QUERY = graphql(`
  query AdminRfids(
    $limit: Int
    $offset: Int
    $rfid_number: String!
    $rfid_status: [eatfresh_rfid_status_enum!]!
    $article_id: uuid_comparison_exp
  ) {
    eatfresh_rfid_tag(
      where: {
        rfid_number: { _ilike: $rfid_number }
        rfid_status: { _in: $rfid_status }
        article_id: $article_id
      }
      order_by: { rfid_number: asc }
      limit: $limit
      offset: $offset
    ) {
      ...Rfid
    }
    eatfresh_rfid_tag_aggregate(
      where: {
        rfid_number: { _ilike: $rfid_number }
        rfid_status: { _in: $rfid_status }
        article_id: $article_id
      }
    ) {
      aggregate {
        count
      }
    }
  }
`);

export const ADMIN_ARTICLES_QUERY = graphql(`
  query AdminArticles($articleName: String!, $limit: Int!, $offset: Int!) {
    eatfresh_article(
      where: { name: { _ilike: $articleName } }
      order_by: { name: asc }

      limit: $limit
      offset: $offset
    ) {
      ...Article
      article_detail {
        ...ArticleDetails
      }
      rfid_tags_aggregate {
        aggregate {
          count
        }
      }
    }
    eatfresh_article_aggregate(where: { name: { _ilike: $articleName } }) {
      aggregate {
        count
      }
    }
  }
`);

export const FRIDGES_MAP_QUERY = graphql(`
  query FridgesMap {
    eatfresh_fridge {
      id
      given_id
      latitude
      longitude
      location
    }
  }
`);

export const UNSTORED_RFIDS_QUERY = graphql(`
  query UnstoredRfids {
    eatfresh_rfid_tag(where: { rfid_status: { _eq: UNSTORED } }) {
      ...Rfid
    }
  }
`);

export const ADMIN_VAT_CATEGORIES_QUERY = graphql(`
  query AdminVatCategories($limit: Int!, $offset: Int!) {
    eatfresh_vat_category(limit: $limit, offset: $offset) {
      ...VatCategory
    }
    eatfresh_vat_category_aggregate {
      aggregate {
        count
      }
    }
  }
`);

export const SELECT_VAT_CATEGORIES_QUERY = graphql(`
  query SelectVatCategories {
    eatfresh_vat_category {
      ...VatCategory
    }
  }
`);
export const PASSWORD_RESET_TOKEN_QUERY = graphql(`
  query PasswordResetToken($token: String!) {
    eatfresh_password_reset_token(where: { token: { _eq: $token } }) {
      ...PasswordResetToken
    }
  }
`);
export const ARTICLE_BY_PK = graphql(`
  query ARTICLE_BY_PK($articleId: uuid!) {
    eatfresh_article_by_pk(id: $articleId) {
      ...Article
    }
  }
`);
export const ARTICLE_WITH_DETAILS_QUERY = graphql(`
  query ArticleWithDetails($articleId: uuid!) {
    eatfresh_article_by_pk(id: $articleId) {
      ...Article
      article_detail {
        ...ArticleDetails
      }
    }
  }
`);

export const RFID_SCANNERS_QUERY = graphql(`
  query RfidScanners {
    eatfresh_rfid_scanner {
      ...RfidScanner
    }
  }
`);

export const ARTICLE_BY_EAN_QUERY = graphql(`
  query ArticleByEan($ean: String!) {
    eatfresh_article(where: { ean: { _ilike: $ean } }) {
      ...Article
    }
  }
`);

export const RFID_BOXES_QUERY = graphql(`
  query RfidBoxes {
    eatfresh_rfid_box {
      ...RfidBox
      rfid_asset {
        ...RfidAsset
      }
    }
  }
`);

export const WAREHOUSE_QUERY = graphql(`
  query Warehouses {
    eatfresh_warehouse {
      ...Warehouse
    }
  }
`);

export const ADMIN_WAREHOUSE_QUERY = graphql(`
  query AdminWarehouses($name: String!, $limit: Int!, $offset: Int!) {
    eatfresh_warehouse(
      where: { name: { _ilike: $name } }
      order_by: { name: asc }
      limit: $limit
      offset: $offset
    ) {
      ...Warehouse
    }
    eatfresh_warehouse_aggregate(where: { name: { _ilike: $name } }) {
      aggregate {
        count
      }
    }
  }
`);

export const WAREHOUSE_ORDER_QUERY = graphql(`
  query WarehouseOrder {
    eatfresh_warehouse_order {
      ...WarehouseOrder
      warehouse_order_receipt {
        uploaded_file {
          url
        }
      }
    }
  }
`);

export const WAREHOUSE_ORDER_POSITIONS_QUERY = graphql(`
  query WarehouseOrderPositionsByPk($orderId: uuid!) {
    eatfresh_warehouse_order_by_pk(id: $orderId) {
      ...WarehouseOrder
      warehouse_order_receipt {
        id
      }
      warehouse_order_positions {
        article_id
        created_at
        id
        quantity
        updated_at
        warehouse_order_id
        article {
          name
          uploaded_file {
            url
          }
        }
      }
    }
  }
`);
export const WAREHOUSE_ORDER_RECEIPT_POSITIONS_QUERY = graphql(`
  query WarehouseOrderReceiptPositionsByPk($orderId: uuid!) {
    eatfresh_warehouse_order_receipt_by_pk(id: $orderId) {
      warehouse_order_receipt_positions {
        warehouse_order_receipt_id
        article_id
        created_at
        id
        updated_at
        usable_quantity
        quality
        expected_quantity
        best_before_date
        article {
          name
          article_detail {
            storage_box_capacity
          }
        }
        actual_quantity
      }
    }
  }
`);
export const ADMIN_WAREHOUSE_ORDER_QUERY = graphql(`
  query AdminWarehouseOrders($supplier: String!, $limit: Int!, $offset: Int!) {
    eatfresh_warehouse_order(
      where: { supplier: { _ilike: $supplier } }
      order_by: { supplier: asc }
      limit: $limit
      offset: $offset
    ) {
      ...WarehouseOrder
    }
    eatfresh_warehouse_order_aggregate(
      where: { supplier: { _ilike: $supplier } }
    ) {
      aggregate {
        count
      }
    }
  }
`);

export const ADMIN_WAREHOUSE_ORDER_BY_PK_QUERY = graphql(`
  query AdminWarehouseOrderByPk($id: uuid!, $offset: Int!, $pageSize: Int!) {
    eatfresh_warehouse_order_by_pk(id: $id) {
      ...WarehouseOrder
      warehouse_order_positions(offset: $offset, limit: $pageSize) {
        id
        article {
          name
          id
        }
        quantity
      }
    }

    eatfresh_warehouse_order_position_aggregate(
      where: { warehouse_order_id: { _eq: $id } }
    ) {
      aggregate {
        count
      }
    }
  }
`);

export const ADMIN_WAREHOUSE_ORDER_QUERY_POSITIONS_NEW = graphql(`
  query ADMIN_WAREHOUSE_ORDER_QUERY_POSITIONS_NEW(
    $offset: Int!
    $pageSize: Int!
    $before: timestamp
    $after: timestamp
    $selectedStatuses: [eatfresh_warehouse_order_status_enum!]!
  ) {
    eatfresh_warehouse_order(
      where: {
        created_at: { _lt: $before, _gt: $after }
        warehouse_order_status: { _in: $selectedStatuses }
      }
      order_by: { created_at: desc }
      limit: $pageSize
      offset: $offset
    ) {
      ...WarehouseOrder
    }
    eatfresh_warehouse_order_aggregate(
      where: {
        created_at: { _lt: $before, _gt: $after }
        warehouse_order_status: { _in: $selectedStatuses }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`);
export const LABEL_WAREHOUSE_ORDER_RECEIPT_POSITIONS = graphql(`
  query LabelWarehouseOrderReceiptPositions {
    eatfresh_warehouse_order_receipt {
      warehouse_order {
        id
        supplier
        created_at
      }
      id
      warehouse_order_receipt_positions(
        where: {
          _or: [
            { labeling_process: { _is_null: true } }
            {
              article_labeling_process: { status: { _in: [CANCELLED, ERROR] } }
            }
          ]
        }
      ) {
        article_id
        created_at
        id
        updated_at
        usable_quantity
        quality
        expected_quantity
        best_before_date
        article {
          name
          ean
          article_detail {
            storage_box_capacity
          }
        }
        actual_quantity
        article_labeling_process {
          status
          id
        }
      }
    }
  }
`);

export const EATFRESH_ORDER_RECEIPT_POSITION_WITH_ARTICLE_DETAILS_BY_PK =
  graphql(`
    query EATFRESH_ORDER_RECEIPT_POSITION_WITH_ARTICLE_DETAILS_BY_PK(
      $positionId: uuid!
    ) {
      eatfresh_warehouse_order_receipt_position_by_pk(id: $positionId) {
        usable_quantity
        best_before_date
        article_labeling_process {
          status
          id
        }
        article_id
        article {
          id
          name
          ean
          uploaded_file {
            url
          }
          article_detail {
            storage_box_capacity
            items_per_package_unit
          }
        }
      }
    }
  `);

export const SCANNER_EXISTS_BY_PK = graphql(`
  query SCANNER_EXISTS_BY_PK($id: uuid!) {
    eatfresh_rfid_scanner_by_pk(id: $id) {
      given_id
    }
  }
`);
export const SCANNER_EXISTS_BY_GIVEN_ID = graphql(`
  query SCANNER_EXISTS_BY_GIVEN_ID($givenId: String!) {
    eatfresh_rfid_scanner(where: { given_id: { _eq: $givenId } }) {
      given_id
      id
    }
  }
`);
export const WAREHOUSE_ORDER_RECEIPT_POSITIONS_BY_EAN = graphql(`
  query WAREHOUSE_ORDER_RECEIPT_POSITIONS_BY_EAN($ean: String!) {
    eatfresh_article(where: { ean: { _eq: $ean } }) {
      name
      id
      uploaded_file {
        url
      }
      warehouse_order_receipt_positions(
        where: {
          _or: [
            { labeling_process: { _is_null: true } }
            {
              article_labeling_process: { status: { _in: [CANCELLED, ERROR] } }
            }
          ]
        }
      ) {
        id
        usable_quantity
        warehouse_order_receipt {
          warehouse_order {
            created_at
            supplier
          }
        }
      }
    }
  }
`);
export const WAREHOUSE_ORDER_RECEIPT_POSITIONS_BY_EAN_AND_BEST_BEFORE = graphql(
  `
    query WAREHOUSE_ORDER_RECEIPT_POSITIONS_BY_EAN_AND_BEST_BEFORE(
      $ean: String!
      $best_before_date_gte: timestamp!
      $best_before_date_lte: timestamp!
    ) {
      eatfresh_article(where: { ean: { _eq: $ean } }) {
        name
        id
        uploaded_file {
          url
        }
        warehouse_order_receipt_positions(
          where: {
            best_before_date: {
              _lte: $best_before_date_lte
              _gte: $best_before_date_gte
            }
          }
        ) {
          id
          usable_quantity
          article {
            name
            uploaded_file {
              url
            }
          }
          warehouse_order_receipt {
            warehouse_order {
              created_at
              supplier
            }
          }
        }
      }
    }
  `,
);

export const ADMIN_FRIDGE_TARGET_STOCK_QUERY = graphql(`
  query AdminFridgeTargetStock($limit: Int!, $offset: Int!, $fridgeId: uuid!) {
    eatfresh_fridge_by_pk(id: $fridgeId) {
      ...Fridge
      fridge_target_stock {
        ...FridgeTargetStock
        fridge_target_stock_positions(limit: $limit, offset: $offset) {
          ...FridgeTargetStockPosition
          article {
            ...Article
          }
        }

        fridge_target_stock_positions_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  }
`);

export const SUPPLIER_FRIDGE_ORDER_QUERY = graphql(`
  query AdminFridgeOrder($fridgeOrderId: uuid!) {
    eatfresh_fridge_order_by_pk(id: $fridgeOrderId) {
      ...FridgeOrder
      fridge {
        ...Fridge
        fridge_target_stock {
          ...FridgeTargetStock
          fridge_target_stock_positions {
            ...FridgeTargetStockPosition
            article {
              ...Article
            }
          }
        }
      }
    }
  }
`);
