// Sources: https://www.apollographql.com/docs/react/migrating/apollo-client-3-migration/
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  split,
} from '@apollo/client/core';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { onError } from '@apollo/client/link/error';

console.log('APOLLO :: ', import.meta.env);
const { wsUrl, httpUrl } = {
  wsUrl: import.meta.env.VITE_HASURA_WS_URL,
  httpUrl: import.meta.env.VITE_HASURA_URL,
};

const httpLink = (authToken: string) => {
  const header: Record<string, string> = {};
  // only send header if a token is present, otherwise hasura will try to authenticate the request
  if (authToken) {
    header.Authorization = `Bearer ${authToken}`;
  }
  // FIXME : remove this before go live
  return new HttpLink({
    uri: httpUrl,
    headers: header,
  });
};

// Create a WebSocket link:
const wsLink = (authToken: string) => {
  const header: { Authorization?: string; 'x-hasura-admin-secret'?: string } =
    {};
  if (authToken) {
    header.Authorization = `Bearer ${authToken}`;
  }

  // FIXME : remove this before go live
  header['x-hasura-admin-secret'] = import.meta.env.VITE_HASURA_ADMIN_SECRET;

  return new WebSocketLink({
    uri: wsUrl,
    options: {
      lazy: true,
      reconnect: true,
      timeout: 30000,
      inactivityTimeout: 30000,
      connectionParams: {
        headers: header,
      },
    },
  });
};

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = (authToken: string) => {
  return split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink(authToken),
    httpLink(authToken),
  );
};

const errorLink = function () {
  return onError(({ graphQLErrors, networkError, operation }) => {
    console.error(
      'GraphQlError:',
      graphQLErrors?.map((e) => e.message),
    );
    console.error('NetworkError:', networkError?.message);
    console.log('OPERATION', operation);

    if (graphQLErrors) {
      const graphQlError = graphQLErrors[0];

      // TODO handle logged out error
      // if (graphQlError && graphQlError.extensions && (graphQlError.extensions.httpStatusEquivalent === 401 || graphQlError.extensions.code === 'validation-failed')) {
      //   Vue.set(state.AuthModule, 'showLoginDialog', true)
      // } else {
      //   Vue.set(state.UiModule, 'showDefaultError', true)
      // }
    }
  });
};

const authToken = ''; // TODO get auth token from user store
const apolloClient = new ApolloClient({
  link: errorLink().concat(link(authToken)),
  cache: new InMemoryCache({
    addTypename: false,
  }),
  connectToDevTools: ['localhost'].includes(window.location.hostname),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      notifyOnNetworkStatusChange: true,
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      notifyOnNetworkStatusChange: true,
    },
  },
});

export const client = () => {
  return apolloClient;
};

export function setApolloAuthToken(token: string) {
  apolloClient.setLink(link(token));
}
